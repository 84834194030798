import {
  PAL_INTERNAL_ACCOUNT,
  PAL_EXTERNAL_ACCOUNT,
  PAL_DEBIT_CARD,
  PAL_FUNDING_TYPE_INTERNAL_ACCOUNT,
  PAL_FUNDING_TYPE_EXTERNAL_ACCOUNT,
  PAL_FUNDING_TYPE_DEBIT_CARD,
} from 'consts';
import { formatCurrency } from '../../util';

const BuildDerivedId = (fundingType) => {
  let derivedValue = '';

  switch (fundingType) {
    case PAL_FUNDING_TYPE_INTERNAL_ACCOUNT:
      derivedValue = PAL_INTERNAL_ACCOUNT;
      break;
    case PAL_FUNDING_TYPE_EXTERNAL_ACCOUNT:
      derivedValue = PAL_EXTERNAL_ACCOUNT;
      break;
    case PAL_FUNDING_TYPE_DEBIT_CARD:
      derivedValue = PAL_DEBIT_CARD;
      break;
    default:
      break;
  }
  return derivedValue;
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = ({ ...item } = {}) => {
  item.feesAmountDerived = formatCurrency(item?.feesAmount);
  item.fundingTypeDerived = BuildDerivedId(item?.fundingType);
  return {
    ...item,
  };
};
